import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SecondaryHeader from "../../components/SecondaryHeader"
import SupportCenterTopicWrapper from "../../components/SupportCenterLayout"
import CustomLink from "../../components/CustomLink"
import { PORTAL_URL } from "../../utils/Constants"

const SigningIntoYourDashboardPage = () => {
  return (
    <Layout>
      <Seo title={`Easy to use cloud platform`} />
      <SecondaryHeader title={<>Support Center</>} />

      <div id="support-center-page">
        <section className="support-center">
          <div style={{ paddingBottom: "50px" }}></div>

          <SupportCenterTopicWrapper>
            <div className="support-center__topic-right">
              <h3 className="mb-4">Signing into your dashboard</h3>

              <p>
                Welcome back! Easily access all of your AlignerBase account
                features by signing in to your personalized dashboard.
              </p>
              <div className="mb-4" />

              <ol className="list-decimal mx-4">
                <li>
                  Go to
                  {/* <CustomLink title="URL" link={PORTAL_URL} /> */}
                  <CustomLink title="URL" link="#" />
                  , then sign in with your email.
                  <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/3.jpg" />
                  <ul class="list-disc pl-4">
                    <li>
                      Your username is the email address you submitted to
                      register for AlignerBase.
                    </li>
                  </ul>
                </li>
                <li>
                  Upon successful sign in, you should now be able to view the
                  main dashboard.
                </li>
              </ol>
            </div>
          </SupportCenterTopicWrapper>
        </section>
      </div>
    </Layout>
  )
}

export default SigningIntoYourDashboardPage
